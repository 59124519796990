import Cookie from "cookie.js";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
    }

    return mergeDeep(target, ...sources);
}


export function getTotalPercentage(props, section) {
    let data = props.state[section];
    let total = 0;

    if (!!data) {
        Object.keys(data).forEach(d => {
            total += data[d].value;
        })
    }

    return total;
}

export function loggedin() {
    let storage = Cookie.get('userLogged');
    let data = storage ? JSON.parse(storage) : null;

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}

export function userType() {
    let storage = localStorage.getItem('userType');
    let data = JSON.parse(storage);

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}

export function boardPosition() {
    let data = localStorage.getItem('position');

    if (!!data) {
        return data;
    } else {
        return 'right';
    }
}

/* ... */
export function hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/[\r\n]/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '))
    );
}


export function logout(props) {
    props.setData({
        questions: mergeDeep({}, props.state.dataBackup.questions),
        time: mergeDeep({}, props.state.dataBackup.time),
        meaning: mergeDeep({}, props.state.dataBackup.meaning),
        infoBubble: mergeDeep({}, props.state.dataBackup.infoBubble),
        userLogged: false,
        userType: '',
        users: [],
        position: 'right',
        route: '/',
        logout: false
    });

    localStorage.removeItem('userLogged');
}

export function getTranslation(self, language, str) {
    if (!!self.props.state.language && self.props.state.language[language]) {
        if (!!self.props.state.language[language][str]) {
            return self.props.state.language[language][str]
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function getTranslationHeadline(self, lang, str) {
    if (!!self && self[lang] && self[lang][str]) {
        if (!!self[lang][str]) {
            return self[lang][str]
        } else {
            return '';
        }
    } else {
        return '';
    }
}
