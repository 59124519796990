import React from 'react';
import {Page, Text, View, Document, Font, Image, Link} from '@react-pdf/renderer';

import regular from '../assets/font/Merriweather-Regular.ttf';
import bold from '../assets/font/Merriweather-Bold.ttf';
import italic from '../assets/font/Merriweather-Italic.ttf';

import plus from '../assets/img/plus.png';
import minus from '../assets/img/line.png';

import bottomLine from '../assets/img/bottomLine.png';

import shadow from '../assets/img/shadow.png';


Font.register({
    family: 'Open Sans',
    fonts: [
        {src: regular},
        {src: bold, fontWeight: 700},
        {src: italic, fontStyle: 'italic'}
    ]
});


function RenderQuarter(props) {

    let tr = [];

    for (let i = 0; i < 6; i++) {
        let tds = [];

        for (let j = -5; j <= 5; j++) {
            tds.push(
                <View key={'td_' + i + '_' + j} style={{flex:1, borderLeft:'1 solid ' + props.raster, borderTop:'1 solid ' + props.raster, width:'9.09%', height:'100%', backgroundColor:(j===0 ? props.zero : '')}}>
                    {j === props.data[i].value &&
                        <Image src={'assets/img/coins/' + props.data[i].coin} style={{height:'100%'}} />
                    }
                </View>
            )
        }

        tr.push(
            <View key={'tr_' + i} style={{display:'flex', flexDirection:'row', height:'9.09%'}}>
                {tds}
            </View>
        );
    }

    let meaning = [];

    for (let i = 0; i <= 100; i=i+5) {
        meaning.push(
            <View key={'tdm_' + i} style={{flex:1, color:'#fff', display:'flex', flexDirection:'column', justifyContent:'center', zIndex:'0', position:'relative', backgroundColor:(props.state.Meaning['questions'][props.state.lang][props.section].value >= i ? '#565656' : '')}}>
                <Text>{i % 10 === 0 ? i + '%' : ''}</Text>
                {i === props.state.Meaning['questions'][props.state.lang][props.section].value &&
                    <Image src={'assets/img/coins/' + props.state.Meaning['questions'][props.state.lang][props.section].coin} style={{height:'100%', width:'200%', position:'absolute', top:'0', left:'-50%', zIndex:'100'}} />
                }
            </View>
        )
    }
    let meaningView = (
        <View style={{display:'flex', flexDirection:'row', fontSize:4, textAlign:'center', height:'9.09%', borderLeft:'1 solid ' + props.raster, borderTop:'1 solid ' + props.raster}}>
            <View style={{width:'2%', backgroundColor:'#565656'}} />
            <View style={{width:'96%', display:'flex', flexDirection:'row'}}>
                {meaning}
            </View>
            <View style={{width:'2%'}} />
        </View>
    );

    let time = [];

    for (let i = 0; i <= 100; i=i+5) {
        time.push(
            <View key={'tdt_' + i} style={{flex:1, color:'#fff', display:'flex', flexDirection:'column', justifyContent:'center', zIndex:'0', position:'relative', backgroundColor:(props.state.Time['questions'][props.state.lang][props.section].value >= i ? '#cccccc' : '')}}>
                <Text>{i % 10 === 0 ? i + '%' : ''}</Text>
                {i === props.state.Time['questions'][props.state.lang][props.section].value &&
                    <Image src={'assets/img/coins/' + props.state.Time['questions'][props.state.lang][props.section].coin} style={{height:'100%', width:'200%', position:'absolute', top:'0', left:'-50%', zIndex:'100'}} />
                }
            </View>
        )
    }
    let timeView = (
        <View style={{display:'flex', flexDirection:'row', fontSize:4, textAlign:'center', height:'9.09%', borderLeft:'1 solid ' + props.raster, borderTop:'1 solid ' + props.raster}}>
            <View style={{width:'2%', backgroundColor:'#cccccc'}} />
            <View style={{width:'96%', display:'flex', flexDirection:'row'}}>
                {time}
            </View>
            <View style={{width:'2%'}} />
        </View>
    )

    let description = (
        <View style={{display:'flex', flexDirection:'column', justifyContent:'center', fontSize:16, textAlign:'center', height:'27.27%', borderLeft:'1 solid ' + props.raster, borderTop:'1 solid ' + props.raster, color:'#fff'}}>
            <Text>
                {props.top}
            </Text>
        </View>
    );

    return (
        <View style={{flex:'1 1 38vw', display:'flex', flexDirection:'column', width:'38vw', height:'38vw', boxSizing:'border-box', position:'relative'}}>
            <Image src={props.background} style={{position:'absolute', minWidth:'100%', minHeight:'100%', display:'block', height:'100%', width:'100%'}} />

            <Image src={plus} style={{position:'absolute', display:'block', height:'20%', width:'20%', top:(props.display !== 'top' ? '18%' : null), bottom:(props.display === 'top' ? '17%' : null), right:'13%'}} />
            <Image src={minus} style={{position:'absolute', display:'block', height:'20%', width:'20%', top:(props.display !== 'top' ? '18%' : null), bottom:(props.display === 'top' ? '17%' : null), left:'13%'}} />

            {props.display === 'top' &&
                <>
                    {description}

                    {meaningView}

                    {timeView}
                </>
            }

            {tr}

            {props.display === 'bottom' &&
                <>
                    {meaningView}

                    {timeView}

                    {description}
                </>
            }
        </View>
    )
}

function RenderGame(props) {
    let text = props.state['Auswertung'][props.state.lang]['Date'] + ': ' + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });

    let fields = [];
    let i = 0;
    if (!!props.state.structure && props.state.structure.size > 0) {
        props.state.structure.forEach((v, k) => {
            let position = 'bottom';

            if (i >= 2) {
                position = 'top';
            }

            let zero = '';
            let raster = '';

            if (k === 'Sinn') {
                zero = '#8d2e00';
                raster = '#ba714e';
            } else if (k === 'Arbeit') {
                zero = '#017598';
                raster = '#4da8c1';
            } else if (k === 'Gesundheit') {
                zero = '#8fa524';
                raster = '#bccd69';
            } else if (k === 'Soziale-Beziehungen') {
                zero = '#dea92b';
                raster = '#f7d06e';
            }

            fields.push(
                <RenderQuarter {...props} key={k} section={k} display={position} top={props.state['questions'][props.state.lang][k][0]['section']} background={props.state['backgrounds'][props.state.lang][k]} data={props.state['questions'][props.state.lang][k]} zero={zero} raster={raster} />
            )
            i++;
        })
    }


    return (
        <View>
            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                <Text style={{textTransform:'uppercase', display:'flex', fontSize:14, borderBottom:'1 solid ' + props.state.config['Farbe'], padding:'0 20'}}>
                    {props.state['Auswertung'][props.state.lang]['Ueberschrift']}
                </Text>
            </View>

            <View style={{display:'flex', flexDirection:'row', marginBottom:20}}>
                <Text style={{display:'flex', fontSize:10, whiteSpace:'pre-line', fontStyle:'italic', textAlign:'center'}}>
                    {text}
                </Text>
            </View>

            <View style={{border:'1pt solid #646464', width:"78.5vw"}}>
                <View style={{display:'flex', flexDirection:'row', flexWrap:'wrap', position:'relative', margin:"1vw", width:"76vw"}}>
                    {fields}
                </View>
            </View>
        </View>
    )
}

function RenderResults(props) {
    return (
        <View style={{marginTop:'40px'}}>

            <View style={{marginBottom:30, fontSize:10}}>
                <Text style={{marginBottom:5}}>{props.state['Auswertung'][props.state.lang]['Frage_1']}</Text>

                <Text style={{lineHeight:'1.55'}}>
                    {!!props.state['results'].result_1 ? props.state['results'].result_1 : '-'}
                </Text>
            </View>

            <View style={{marginBottom:30, fontSize:10}}>
                <Text style={{marginBottom:5}}>{!!props.state['Auswertung'][props.state.lang]['Frage_2'] ? props.state['Auswertung'][props.state.lang]['Frage_2'] : '-'}</Text>

                <View style={{height:66, width:'100%', position:'relative'}}>
                    <Image src={bottomLine} style={{position:'absolute', height:46, display:'block', width:'100%', top:0, right:0, bottom:0, left:0}} />

                    <Text style={{lineHeight:'1.55'}}>
                        {!!props.state['results'].result_2 ? props.state['results'].result_2 : '-'}
                    </Text>
                </View>
            </View>

            <View style={{marginBottom:30, fontSize:10}}>
                <Text style={{marginBottom:5}}>{!!props.state['Auswertung'][props.state.lang]['Frage_3'] ? props.state['Auswertung'][props.state.lang]['Frage_3'] : '-'}</Text>

                <View style={{height:66, width:'100%', position:'relative'}}>
                    <Image src={bottomLine} style={{position:'absolute', height:46, display:'block', width:'100%', top:0, right:0, bottom:0, left:0}} />

                    <Text style={{lineHeight:'1.55'}}>
                        {!!props.state['results'].result_3 ? props.state['results'].result_3 : '-'}
                    </Text>
                </View>
            </View>

            <View style={{marginBottom:30, fontSize:10}}>
                <Text style={{marginBottom:5}}>{!!props.state['Auswertung'][props.state.lang]['Frage_4'] ? props.state['Auswertung'][props.state.lang]['Frage_4'] : '-'}</Text>

                <View style={{height:66, width:'100%', position:'relative'}}>
                    <Image src={bottomLine} style={{position:'absolute', height:46, display:'block', width:'100%', top:0, right:0, bottom:0, left:0}} />

                    <Text style={{lineHeight:'1.55'}}>
                        {!!props.state['results'].result_4 ? props.state['results'].result_4 : '-'}
                    </Text>
                </View>
            </View>

        </View>
    );
}

function RenderQuestions(props) {

    if (!!props.state.questions && !!props.state.questions[props.state.lang][props.section]) {

            let questions = Object.keys(props.state.questions[props.state.lang][props.section]).map(d => {
                let data = props.state.questions[props.state.lang][props.section][d];

                let val = [];
                for(let j = -5; j<=5; j++) {
                    val.push(
                        <View style={{width:25, height:25, textAlign:'center', border:'1 solid #ccc', borderRadius:25, margin:2, display:'flex', flexDirection:'column', justifyContent:'center'}} key={'q_' + d + '_' + j}>
                            {data['value'] === j ?
                                <Image src={'assets/img/coins/' + data['coin']} style={{width:23, height:23}} />
                                :
                                <Text style={{fontSize:j === 0 ? 6 : 10, color:'#ccc'}}>{j === 0 ? 'Start' : j}</Text>
                            }
                        </View>
                    )
                }

                return (
                    <View style={{marginBottom:10}} key={'q_' + d}>
                        <View style={{marginBottom:5}}>
                            <Text style={{fontSize:10, textAlign:'center'}}>{data['question']}</Text>
                        </View>

                        <View style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {val}
                        </View>
                    </View>
                )
            });

            return (
                <View key={'page_' + props.section} style={props.style}>
                    <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                        <Text style={{textTransform:'uppercase', display:'flex', fontSize:14, borderBottom:'1 solid ' + props.state.config['Farbe'], padding:'0 20'}}>
                            {props.state.headlines[props.state.lang][props.section]}
                        </Text>
                    </View>

                    <View>

                        {questions}

                    </View>
                </View>
            );
    }

}

function RenderTimeMeaning(props) {

    let questions = [];

    if (!!props.state[props.section] && !!props.state[props.section]['questions'] && !!props.state[props.section]['questions'][props.state.lang]) {
        for(let i in props.state[props.section]['questions'][props.state.lang]) {
            if (props.state[props.section]['questions'][props.state.lang].hasOwnProperty(i)) {

                let data = props.state[props.section]['questions'][props.state.lang][i];

                let val = [];
                for(let j = 0; j<=100; j=j+5) {
                    val.push(
                        <View style={{width:20, height:20, textAlign:'center', border:'1 solid #ccc', borderRadius:20, margin:'2 1', display:'flex', flexDirection:'column', justifyContent:'center'}} key={'tm_' + i + '_' + j}>
                            {data['value'] === j ?
                                <Image src={'assets/img/coins/' + data['coin']} style={{width:18, height:18}} />
                                :
                                <Text style={{fontSize:6, color:'#ccc'}}>{j}</Text>
                            }
                        </View>
                    )
                }

                questions.push(
                    <View style={{marginBottom:10}} key={'q_' + i}>
                        <View style={{marginBottom:5}}>
                            <Text style={{fontSize:10, textAlign:'center'}}>{data['section']}</Text>
                        </View>

                        <View style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {val}
                        </View>
                    </View>
                );
            }
        }
    }

    return (
        <View key={props.section} style={{marginBottom:'40px'}}>
            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                <Text style={{textTransform:'uppercase', display:'flex', fontSize:14, borderBottom:'1 solid ' + props.state.config['Farbe'], padding:'0 20'}}>{props.state[props.section][props.state.lang]['Ueberschrift']}</Text>
            </View>

            <View>
                {questions}
            </View>
        </View>
    );

}

function RenderPages(props) {

    let arr = [];

    arr.push(
        <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_game'}>
            <RenderGame {...props} key={'game'} />
        </Page>
    );

    arr.push(
        <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_results'}>
            <RenderResults {...props} key={'results'} />
        </Page>
    );

    if (!!props.state['structure'] && props.state['structure'].size > 0) {
        arr.push(
            <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_qestions_1'}>
                <RenderQuestions {...props} section={[...props.state['structure']][0][0]} key={'questions_' + [...props.state['structure']][0][0]} style={{marginBottom:30}} />
                <RenderQuestions {...props} section={[...props.state['structure']][1][0]} key={'questions_' + [...props.state['structure']][1][0]} />
            </Page>
        );

        arr.push(
            <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_qestions_2'}>
                <RenderQuestions {...props} section={[...props.state['structure']][2][0]} key={'questions_' + [...props.state['structure']][2][0]} style={{marginBottom:30}} />
                <RenderQuestions {...props} section={[...props.state['structure']][3][0]} key={'questions_' + [...props.state['structure']][3][0]} />
            </Page>
        );
    }

    arr.push(
        <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw', fontSize:8, boxSizing:'border-box'}} key={'page_timeMeaning'}>
            <RenderTimeMeaning {...props} key={'meaning'} section={'Meaning'} data={props.state['Meaning']['questions'][props.state.lang]} />
            <RenderTimeMeaning {...props} key={'time'} section={'Time'} data={props.state['Time']['questions'][props.state.lang]} />

            <View style={{position:'absolute', right:5, bottom:5, left:5}}>
                <Image src={shadow} style={{width:'100%'}} />

                <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'0 15'}}>
                    <Link src={'https://balanceaktiv.de'} style={{marginTop:'auto', marginBottom:'auto'}}>
                        <Image src={'assets/img/balance_aktiv.png'} style={{width:'80px'}} />
                    </Link>

                    <Link src={props.state.config['Link']} style={{marginTop:'auto', marginBottom:'auto'}}>
                        <Image src={props.state.config['Logo']} style={{width:'80px'}} />
                    </Link>
                </View>
            </View>
        </Page>
    );

    return arr;
}

export function PdfDocument(props) {
    return (
        <Document>
            <RenderPages {...props.data} />
        </Document>
    )
}
