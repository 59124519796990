import React, {Component} from 'react';
import {withRouter, Link, Switch, Route, useHistory} from 'react-router-dom';
import {connect} from "react-redux";
import {getTotalPercentage, getTranslation, getTranslationHeadline, logout, mergeDeep} from "./helper";
import {setData} from "../redux/actions";

import Form from './form.class';
import TimeMeaning from './timeMeaning.class';
import Start from './start.class';
import Results from './results.class';
import Imprint from './imprint.class';
import Modal from "./modal.class";

import '../assets/scss/forms.scss';

import {ReactComponent as Home} from '../assets/img/home.svg';
import {ReactComponent as Menu} from '../assets/img/menu.svg';

const add = (a, b) => a + b;

function LinkTo(props) {
    let history = useHistory();

    async function navigate(e, to, disabled) {
        if (disabled) return;

        let timestamp = new Date().getTime();

        props.props.setData({
            route: props.to,
            timestamp: timestamp
        });

        history.push({
            pathname: props.to
        });
    }


    return (
        (!!props.home) ?
            <Link to={props.to} onClick={e => navigate(e, props.to)} className={props.className}>
                <Home width={20} className={'theme_svg'} />
            </Link>
            :
            <Link to={props.disabled ? '#' : props.to} onClick={e => navigate(e, props.to, props.disabled)} className={props.className}>{props.name}</Link>
    )

}


class Forms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            logout: false
        };

        this.logout = this.logout.bind(this);
    }


    changeRoute(e, route) {
        e.preventDefault();
        this.props.changeRoute(route);
    }

    switchBoard(position) {
        localStorage.setItem('position', position);

        this.props.setData({
            position: position
        });
    }

    getCalc(location) {
        let hasZero = 0;
        let sum = 100;
        if (!!this.props.state['questions'][this.props.state.lang] && !!this.props.state['questions'][this.props.state.lang][location]) {
            hasZero = Object.keys(this.props.state['questions'][this.props.state.lang][location]).filter(e => this.props.state['questions'][this.props.state.lang][location][e].value === 0).length;
        } else if (location === 'Time' && !!this.props.state['Time']['questions'] && Object.keys(this.props.state['Time']['questions'][this.props.state.lang]).length) {
            let num = Object.keys(this.props.state['Time']['questions'][this.props.state.lang]).map(e => this.props.state['Time']['questions'][this.props.state.lang][e].value);
            sum = num.reduce(add);
        } else if (location === 'Meaning' && !!this.props.state['Meaning']['questions'] && Object.keys(this.props.state['Meaning']['questions'][this.props.state.lang]).length) {
            let num = Object.keys(this.props.state['Meaning']['questions'][this.props.state.lang]).map(e => this.props.state['Meaning']['questions'][this.props.state.lang][e].value);
            sum = num.reduce(add);
        }

        return sum === 100 && hasZero === 0;
    }

    logout() {
        this.props.setData({
            questions: mergeDeep({}, this.props.state.dataBackup.questions),
            Time: mergeDeep({}, this.props.state.dataBackup.Time),
            Meaning: mergeDeep({}, this.props.state.dataBackup.Meaning),
            infoBubble: mergeDeep({}, this.props.state.dataBackup.infoBubble),
            results: {},
            position: 'right',
            route: '/',
            userLogged: false
        });
        logout(this.props);

        window.location = 'https://balanceaktiv.de';
    }

    render() {
        let arr = [];
        if (!!this.props.state.structure && this.props.state.structure.size > 0) {
            this.props.state.structure.forEach((v, k) => {
                arr.push('/' + k);
            })
        }

        let footerLinks = {
            '/': {
                next : arr[0]
            },
            [arr[0]]: {
                prev: '/',
                next : arr[1]
            },
            [arr[1]]: {
                prev: arr[0],
                next : arr[2]
            },
            [arr[2]]: {
                prev: arr[1],
                next : arr[3]
            },
            [arr[3]]: {
                prev: arr[2],
                next : '/Bedeutung'
            },
            '/Bedeutung': {
                prev: arr[3],
                next : '/Zeit'
            },
            '/Zeit': {
                prev: '/Bedeutung',
                next : '/Erkenntnisse'
            },
            '/Erkenntnisse': {
                prev: '/Zeit',
                next: 'coach'
            },
            '/Impressum': {}
        };
        let currentLinks = footerLinks[this.props.location.pathname];

        let location = this.props.location.pathname.replace('/', '');
        if (location === 'Bedeutung') {
            location = 'Meaning';
        } else if (location === 'Zeit') {
            location = 'Time';
        }

        let structure = [];
        if (!!this.props.state.structure && this.props.state.structure.size > 0) {
            this.props.state.structure.forEach((v, k) => {
                structure.push(
                    <li key={k} className={'link theme_bordercolor_before ' + (this.getCalc(k) ? '' : 'disabled ') + (this.props.location.pathname === '/' + k ? 'current ' : '')}>
                        <LinkTo to={'/' + k} name={this.props.state['headlines'][this.props.state.lang][this.props.state['questions'][this.props.state.lang][k][0]['section'].replace(' ', '-')]} props={this.props} className={'theme_color_hover ' + (this.getCalc(k) ? '' : 'disabled ') + (this.props.location.pathname === '/' + k ? 'theme_color ' : '')} disabled={!this.getCalc(k)} />
                    </li>
                )
            })
        }

        return (
            <div className={'forms'}>

                <ul className={'navigation'}>
                    <li>
                        <LinkTo to={'/'} name={'Home'} props={this.props} home={true} />
                    </li>
                    {structure}
                    <li className={'link theme_bordercolor_before ' + (this.getCalc('Meaning') ? '' : 'disabled ') + (this.props.location.pathname === '/Bedeutung' ? 'current ' : '')}>
                        <LinkTo to={'/Bedeutung'} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Bedeutung')} props={this.props} className={'theme_color_hover ' + (this.props.location.pathname === '/Bedeutung' ? 'theme_color' : '')} disabled={!this.getCalc('Meaning')} />
                    </li>
                    <li className={'link theme_bordercolor_before ' + (this.getCalc('Time') ? '' : 'disabled ') + (this.props.location.pathname === '/Zeit' ? 'current' : '')}>
                        <LinkTo to={'/Zeit'} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Zeit')} props={this.props} className={'theme_color_hover ' + (this.props.location.pathname === '/Zeit' ? 'theme_color' : '')} disabled={!this.getCalc('Time')} />
                    </li>
                    <li className={'link theme_bordercolor_before ' + (this.getCalc('Time') ? '' : 'disabled ') + (this.props.location.pathname === '/Erkenntnisse' ? 'current' : '')}>
                        <LinkTo to={'/Erkenntnisse'} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Auswertung')} props={this.props} className={'theme_color_hover ' + (this.props.location.pathname === '/Erkenntnisse' ? 'theme_color' : '')} disabled={!this.getCalc('Time')} />
                    </li>
                    <li className={'menu theme_hover'}>
                        <Menu />

                        <ul>
                            <li>
                                <a href={'#switch'} onClick={() => this.switchBoard(this.props.state.position === 'right' ? 'left' : 'right')} className={'theme_color_hover'}>
                                    {getTranslation(this, this.props.state.lang, 'ChangeView')}
                                </a>
                            </li>
                            <li>
                                <LinkTo to={'/Impressum'} name={getTranslation(this, this.props.state.lang, 'Imprint')} props={this.props} className={'theme_color_hover'} />
                            </li>
                            <li>
                                <a href={'#logout'} onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({logout: !this.state.logout})
                                }} className={'theme_color_hover'}>
                                    {getTranslation(this, this.props.state.lang, 'EndSession')}
                                </a>
                            </li>
                        </ul>

                        <Modal button={{
                            title: getTranslation(this, this.props.state.lang, 'Attention'),
                            text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                            className: 'theme_color_hover',
                            id: 'btnStart',
                            confirm: () => this.logout(),
                            changeState: () => {
                                this.setState({
                                    logout: false
                                });
                            },
                            button: getTranslation(this, this.props.state.lang, 'EndSession'),
                            hideButton: true,
                            state: this.state.logout
                        }} />
                    </li>
                </ul>

                <div className={'horizontalShadow'} />

                <div className={'content'}>
                    <Switch>
                        <Route path={'/'} exact key={'start'}>
                            <Start setLanguage={this.props.setLanguage} />
                        </Route>
                        {
                            (!!this.props.state['questions'] && !!this.props.state['questions'][this.props.state.lang] && Object.keys(this.props.state['questions'][this.props.state.lang]).length > 0) && Object.keys(this.props.state['questions'][this.props.state.lang]).map(k => {
                                let f = this.props.state['questions'][this.props.state.lang][k];
                                let infobubble = this.props.state['infoBubble'][this.props.state.lang][k];

                                return (
                                    <Route path={'/' + k} key={k}>
                                        <Form change={this.props.change} section={k} data={f} infoBubble={infobubble} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                                    </Route>
                                )

                            })
                        }
                        <Route path={'/Zeit'} key={'time'}>
                            {!!this.props.state['Time'] && !!this.props.state['Time']['questions'] && Object.keys(this.props.state['Time']['questions']).length &&
                                <TimeMeaning change={this.props.change} section={'Time'} question={7} data={this.props.state['Time']} totalPercantage={getTotalPercentage(this.props, 'Time')} add={this.props.state['Time']} info={this.props.state['Time']} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Time']) ? this.props.state['infoBubble'][this.props.state.lang]['Time'] : {}} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                            }
                        </Route>
                        <Route path={'/Bedeutung'} key={'meaning'}>
                            {!!this.props.state['Meaning'] && !!this.props.state['Meaning']['questions'] && Object.keys(this.props.state['Meaning']['questions']).length &&
                                <TimeMeaning change={this.props.change} section={'Meaning'} question={8} data={this.props.state['Meaning']} totalPercantage={getTotalPercentage(this.props, 'Meaning')} add={this.props.state['Meaning']} info={this.props.state['Meaning']} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Meaning']) ? this.props.state['infoBubble'][this.props.state.lang]['Meaning'] : {}} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                            }
                        </Route>
                        {(this.props.state['Auswertung'] && Object.keys(this.props.state['Auswertung']).length) &&
                        <Route path={'/Erkenntnisse'} key={'results'}>
                            <Results setLanguage={this.props.setLanguage} section={'Auswertung'} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Auswertung']) ? this.props.state['infoBubble'][this.props.state.lang]['Auswertung'] : {}} toggleBlur={this.props.toggleBlur} />
                        </Route>
                        }
                        <Route path={'/Impressum'} key={'imprint'}>
                            <Imprint setLanguage={this.props.setLanguage} />
                        </Route>
                    </Switch>
                </div>

                <div className={'horizontalShadow'} />

                <div className={'footer'}>

                    <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />

                    <div className={'centerBox'}>
                        {(!!currentLinks && currentLinks.prev)
                            ?
                                <LinkTo className={'prev theme_background_hover'} to={currentLinks.prev} name={getTranslation(this, this.props.state.lang, 'Back')} props={this.props} />
                            :
                                <div className={'prev disabled'}>&nbsp;</div>
                        }
                        {(!!currentLinks && !!currentLinks.next)
                            ?
                                (
                                    currentLinks.next === 'coach' ?
                                        <>
                                            <a href={'#logout'} onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({logout: !this.state.logout})
                                            }} className={'theme_background_hover centerButton'}>
                                                {getTranslation(this, this.props.state.lang, 'EndSession')}
                                            </a>
                                            <a href={this.props.state['config']['Coachlink']} target={'_blank'} className={'next theme_background_hover'}>Zum Coach</a>
                                        </>
                                    :
                                        <LinkTo className={'next' + (this.getCalc(location) ? ' theme_background_hover' : ' disable')} to={currentLinks.next} name={this.props.location.pathname === '/' ? getTranslation(this, this.props.state.lang, 'Start') : getTranslation(this, this.props.state.lang, 'Next')} props={this.props} disabled={!this.getCalc(location)} />
                                )
                            :
                                <div className={'next disabled'}>&nbsp;</div>
                        }
                    </div>

                    <a href={this.props.state.config['Logolink']} target={'_blank'} className={'logo'}>
                        <span style={{backgroundImage: 'url(' + this.props.state.config['Logo'] + ')'}} />
                    </a>

                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Forms
    )
);
