import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';

import '../assets/scss/results.scss';
import TopBox from "./topBox.class";
import PdfDownload from "./pdfDownload.class";
import marked from "./markdown";
import {setData} from "../redux/actions";

class Results extends Component {

    constructor(props) {
        super(props);

        this.change = this.change.bind(this);
    }

    change(target) {
        let name = target.name;
        let value = target.value;

        this.props.setData({
            results: Object.assign({}, this.props.state.results, {
                [name]: value
            })
        });
    }

    render() {
        if (!!this.props.state['Auswertung']) {

            let downloadable = this.props.state['Auswertung'][this.props.state.lang]['DownloadErlaubt'];
            let text = this.props.state['Auswertung'][this.props.state.lang]['Unterueberschrift'] + ' ' + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });

            return (
                <div className={'results'}>

                    <TopBox section={this.props.state['Auswertung'][this.props.state.lang]['Ueberschrift']} setLanguage={this.props.setLanguage} />

                    <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                    <div className={'form'}>


                        <div className={'choose'}>
                            <label className={'question'}>
                                {this.props.state['Auswertung'][this.props.state.lang]['Frage_1']}
                            </label>

                            <label className={'answer'} htmlFor={'yes'}>
                                <input type={'radio'} id={'yes'} name={'result_1'} value={'Ja'} checked={!!this.props.state.results['result_1'] && this.props.state.results['result_1'] === 'Ja'} onChange={e => this.change(e.target)} />
                                {this.props.state['Auswertung'][this.props.state.lang]['Ja']}
                            </label>

                            <label className={'answer'} htmlFor={'no'}>
                                <input type={'radio'} id={'no'} name={'result_1'} value={'Nein'} checked={!!this.props.state.results['result_1'] && this.props.state.results['result_1'] === 'Nein'} onChange={e => this.change(e.target)} />
                                {this.props.state['Auswertung'][this.props.state.lang]['Nein']}
                            </label>
                        </div>


                        <label htmlFor={'result_2'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Frage_2']}
                        </label>
                        <textarea className={''} id={'result_2'} name={'result_2'} rows={3} value={!!this.props.state.results['result_2'] ? this.props.state.results['result_2'] : ''} onChange={e => this.change(e.target)} />


                        <label htmlFor={'result_3'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Frage_3']}
                        </label>
                        <textarea className={''} id={'result_3'} name={'result_3'} rows={3} value={this.props.state.results['result_3'] ? this.props.state.results['result_3'] : ''} onChange={e => this.change(e.target)} />


                        <label htmlFor={'result_4'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Frage_4']}
                        </label>
                        <textarea className={''} id={'result_4'} name={'result_4'} rows={3} value={this.props.state.results['result_4'] ? this.props.state.results['result_4'] : ''} onChange={e => this.change(e.target)} />


                        <div className={'buttonBox'}>
                            <PdfDownload data={this.state} downloadable={downloadable} change={this.change}  />
                        </div>

                        <hr />

                        <div className={'shortinfo'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Hinweis']}
                        </div>

                        <div className={'contact'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Telefon']}<br />
                            <strong>{this.props.state['config']['Telefon']}</strong>
                        </div>

                        <div className={'contact'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Mail']}<br />
                            <strong>
                                <a href={'mailto:' + this.props.state['config']['Mail']}>
                                    {this.props.state['config']['Mail']}
                                </a>
                            </strong>
                        </div>

                        <div className={'contact'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Web']}<br />
                            <strong>
                                <a href={this.props.state['config']['Link']} target={'_blank'}>
                                    {this.props.state['config']['Link']}
                                </a>
                            </strong>
                        </div>


                    </div>

                </div>
            );
        } else {
            return <></>;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.state.results,this.props.state.results)) {
            this.setState({
                received: !!this.props.state.results.received ? this.props.state.results.received : '',
                willDo: !!this.props.state.results.willDo ? this.props.state.results.willDo : ''
            })
        }
    }

    componentDidMount() {
        if (Object.keys(this.props['infoBubble']).length) {
            this.props.toggleBlur({
                section: this.props.section,
                data: this.props['infoBubble']
            });
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Results
    )
);
